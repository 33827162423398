class API{
    static serviceId = 'service_hzlhi0i';
    static userId = 'EnnHpLZtTaEt1Owu_';
    static templateIdContact = 'template_f3v0zkb';
    static temlateIdReservation = 'template_cqvnqzb';
    static Sitekey = '6LcJT0cgAAAAAHyAhuHtXggb1RtVpJJcx7h-DNJO';
    static Secretkey= '6LcJT0cgAAAAAFxtAlxxoZYK8ULykWXFDJKjjlYE';
}

export default API;
