import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import img1 from './image/img1.jpeg';
import img2 from './image/img2.jpeg';
import img3 from './image/img3.jpeg';
import img4 from './image/img4.jpeg';
import img5 from './image/img5.jpeg';
import img6 from './image/img6.jpeg';
import img7 from './image/img7.jpeg';
import img8 from './image/img8.jpeg';
import img9 from './image/img9.jpeg';
import img10 from './image/img10.jpeg';
import img11 from './image/img11.jpeg';
import img12 from './image/img12.jpeg';
import img13 from './image/img13.jpeg';
import './style.css';
import { BsDash } from 'react-icons/bs';

/**
* @author
* @function About
**/

const About = (props) => {
    let imgGallery = [
        { src: { src: img1 }, title: 'img1' },
        { src: { src: img2 }, title: 'img2' },
        { src: { src: img3 }, title: 'img3' },
        { src: { src: img4 }, title: 'img4' },
        { src: { src: img5 }, title: 'img5' },
        { src: { src: img6 }, title: 'img6' },
        { src: { src: img7 }, title: 'img7' },
        { src: { src: img8 }, title: 'img8' },
        { src: { src: img9 }, title: 'img9' },
        { src: { src: img10 }, title: 'img10' },
        { src: { src: img11 }, title: 'img11' },
        { src: { src: img12 }, title: 'img12' },
        { src: { src: img13 }, title: 'img13' },
    ]
    const images = imgGallery.map((index) => <img src={index.src} title={index.title} alt="images" />).map((index) => index.props.src);

    return (
        <>
            <section className="about" id="about">
                <h1 className="heading_about"> <span>about</span> us </h1>
                <h2 className="about_underline"><BsDash /></h2>

                <div className="row">

                    <div className="content">
                        <h3>Gurkha Spice Winchester</h3>

                        <p>
                            The Gurkha flavor eatery welcomes you to appreciate modern Indian and bona fide Nepalese cooking.
                            We are a newly opened family-run restaurant in 75 Stoney Lane Weeke, S0226EW.
                            We serve Indian and Nepalese cooking in a friendly way of benefit with incredible quality and freshly arranged nourishment by Nepalese and Indian chefs.

                        </p><p>
                            Our chefs are masters within the craftsmanship of nourishment arrangement and strive for as it were the finest, making beyond any doubt our client gets as it were the most excellent.
                            Our dishes combine the serious and fragrant enhancement of Indian and Nepalese Flavors to bring you supernaturally delicious
                            dishes alongside our nourishment and our neighborliness attempt to speak to Nepal and its culture.</p>

                        <p> Nepal is the Land with the world's most noteworthy point, Mount Everest.
                            It is the origin of Lord Buddha and the arrival of the Gurkhas, who are a courageous army.</p>

                        <p>
                            Nepalese food may be a combination of unpretentious tastes and flavors: ginger, cumin, coriander, chilies, turmeric, Fenugreek, dark cardamom, cloves, cinnamon, nutmeg, dark salt.
                        </p>
                    </div>

                    <div className="image">
                        <Carousel
                            className="carousel"
                            images={images}
                            hasIndexBoard={false}
                            hasMediaButton={false}
                            thumbnailWidth={'25%'}
                            thumbnailHeight={'25%'}
                        />

                    </div>
                </div>

            </section>
        </>

    )

}

export default About
