import React from 'react';
import bannerImage from './image/food.jpeg';
import './bannerstyle.css';

/**
* @author
* @function Banner
**/

const Banner = (props) => {

    return (
        <div className="banner">
            <div className="ImageContainer">
                <img className="bannerImage" src={bannerImage} alt="banner" />
            </div>

            <div className="centered">
                <p>Enjoy Your Meal With Authentic Taste</p>
                <div className='buttons123'>
                    <span className="glf-button reservation" data-glf-cuid="306e02a5-5129-48a1-8a92-e8b4cb803f46" data-glf-ruid="42024d63-e343-4dc4-8f02-767f50ba804f" data-glf-reservation="true">RESERVE A TABLE</span>
                    <span className="glf-button" data-glf-cuid="306e02a5-5129-48a1-8a92-e8b4cb803f46" data-glf-ruid="42024d63-e343-4dc4-8f02-767f50ba804f">ORDER TAKEAWAY</span>
                </div>
            </div>
        </div>

    )

}

export default Banner
