import React from 'react';
import { Navbar, Nav, Container, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import logo from './images/Logo/logo.png';
import './style.css';
// import menu from './assets/menu.pdf';

/**
* @author
* @function Header
**/

const Header = (props) => {

  const renderLinks = () => {
    return (
      <Nav>
        <li className="nav-item">
          <HashLink smooth to="#about"><span className="nav-link">About</span></HashLink>
        </li>
        <li className="nav-item">
          <HashLink smooth to="#contact_head"><span className="nav-link">Contact Us</span></HashLink>
        </li>
        <li className="nav-item">
            <span className="glf-button reservation" data-glf-cuid="306e02a5-5129-48a1-8a92-e8b4cb803f46" data-glf-ruid="42024d63-e343-4dc4-8f02-767f50ba804f" data-glf-reservation="true">RESERVE A TABLE</span>
        </li>
        <li className="nav-item">
          <a href="#order">
            <span className="glf-button" data-glf-cuid="306e02a5-5129-48a1-8a92-e8b4cb803f46" data-glf-ruid="42024d63-e343-4dc4-8f02-767f50ba804f">ORDER TAKEAWAY</span>
          </a>
        </li>
      </Nav>
    );
  }

  return (
    <>
      <Navbar className="nav-color" collapseOnSelect fixed="top" expand="lg" variant="dark" style={{ zIndex: 20 }}>
        <Container fluid>
          <Link to="/" className="navbar-brand"><img src={logo} alt="nothing" /><span>Gurkha Spice Winchester</span></Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            {renderLinks()}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )

}

export default Header